<!--
 * @Descripttion:
 * @version:
 * @Author: kBank
 * @Date: 2023-01-29 17:32:44
-->
<template>
  <div>
    <!-- 用户头 -->
    <personInfo></personInfo>
    <!-- <div class="rt dis_flex">
                      <div class="rti dis_flex">
                        <div :class="['zc', 0 == isClick ? 'zca' : '']" @click="toRegiest(0)">
                          快速注册
                        </div>
                        <div>默认</div>
                      </div>
                      <div class="dis_flex">
                        <div :class="['zc', 1 == isClick ? 'zca' : '']" @click="toRegiest(1)">
                          普通注册
                        </div>
                        <div>设为默认</div>
                      </div>
                    </div> -->
    <!-- 表单 -->
    <el-form :model="ruleForm"
             :rules="rules"
             ref="ruleForm"
             label-position="top"
             status-icon
             class="demo-ruleForm">
      <el-row :gutter="40"
              class="padRow">
        <!-- <el-col :span="6">
                          <el-form-item :label="$t('ENU_REG_AU_2')"
                                        prop="fwzx">
                            <el-input v-model="ruleForm.fwzx"
                                      disabled></el-input>
                          </el-form-item>
                        </el-col> -->
        <el-col :span="6">
          <el-form-item :label="$t('w_0240')"
                        prop="pkGradeVal">
            <el-input v-model="ruleForm.pkGradeVal"
                      disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('PER_DA_3')"
                        prop="pkCountry"
                        class="guoqikang">
            <el-select v-model="ruleForm.pkCountry"
                       placeholder=""
                       @change="changeSelection"
                       ref="select"
                       popper-class="guoqi">
              <el-option v-for="item in countryList"
                         :key="item.pkId"
                         :label="item.name"
                         :value="item.pkId">
                <div class="option_box">
                  <el-image class="option_img"
                            :src="item.nationalFlag2"></el-image>
                  <span v-text="item.shortName"></span>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('PER_DA_4')"
                        class="guoqikang"
                        prop="pkSettleCountry">
            <el-select v-model="ruleForm.pkSettleCountry"
                       :disabled="true"
                       @change="changeSelection1"
                       placeholder=""
                       ref="select1"
                       popper-class="guoqi">
              <el-option v-for="item in countryList"
                         :key="item.pkId"
                         :label="item.name"
                         :value="item.pkId">
                <div class="option_box">
                  <el-image class="option_img"
                            :src="item.nationalFlag2"></el-image>
                  <span v-text="item.shortName"></span>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('N_I_164')"
                        prop="parent">
            <el-input v-model="ruleForm.parent"
             @blur="getQuickReg()"
                      :placeholder="$t('w_0264')"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="40"
              class="padRow">
        <el-col :span="6">
          <el-form-item :label="$t('N_I_165')"
                        prop="parentName">
            <el-input v-model="ruleForm.parentName"
                      :placeholder="$t('w_0265')"
                      disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('N_I_167')"
                        prop="placeParent">
            <el-input v-model="ruleForm.placeParent" :disabled="pt"
                      :placeholder="$t('w_0266')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('N_I_166')"
                        prop="placeParentName">
            <el-input v-model="ruleForm.placeParentName"
                      :placeholder="$t('w_0267')"
                      disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('w_0241')"
                        prop="placeDept">

            <el-select v-model="ruleForm.placeDept" :disabled="pt"
                       :placeholder="$t('w_0268')">
              <el-option v-for="item in deptOption"
                         :key="item.value"
                         :label="item.name"
                         :value="item.value">
              </el-option>
            </el-select>

          </el-form-item>
        </el-col>
        </el-row>
        <el-row :gutter="40" class="padRow">
          <el-col :span="6" v-if="userInfo.specialRegistration == 1">
          <el-form-item :label="$t('CK_KS_14')"
                        prop="memberName">
            <el-input :maxlength="40" v-model="ruleForm.memberName"
                      :placeholder="$t('w_0060')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="userInfo.specialRegistration == 1">
<!-- <el-input v-model="ruleForm.phone"
            :maxlength="userInfo.pkSettleCountry == 1?11:20"
                      oninput="value=value.replace(/[^\d.]/g,'')"
                      :placeholder="$t('w_0053')"></el-input> -->
                      <el-form-item
            :label="$t('w_0052')"
            prop="phone"
            v-if="pkCountry == 1"
          >
            <el-input
              v-model="ruleForm.phone"
              :maxlength="11"
              oninput="value=value.replace(/[^\d.]/g,'')"
              :placeholder="$t('w_0053')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('w_0052')" prop="phone" 
          v-else>
            <el-input
              v-model="ruleForm.phone"
              :maxlength="30"
              :placeholder="$t('w_0053')"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="userInfo.specialRegistration == 0">
          <el-form-item :label="$t('CK_KS_14')"
                        prop="memberName">
            <el-input v-model="ruleForm.memberName"
            :maxlength="40"
                      :placeholder="$t('w_0060')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="userInfo.specialRegistration == 0">
          <el-form-item :label="$t('w_0052')"
                        prop="phone">
            <el-input v-model="ruleForm.phone"
            :maxlength="30"
                      :placeholder="$t('w_0053')"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item :label="$t('w_0061')"
                        prop="deliveryWay">
            <el-select v-model="ruleForm.deliveryWay"
                       :disabled="this.userInfo.pkSettleCountry == 1"
                       @change="selDelivery"
                       :placeholder="$t('w_0062')">
              <el-option v-for="item in deliList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item :label="$t('w_0063')"
                        :required="isDeliveryWay"
                        prop="transType">
            <el-select v-model="ruleForm.transType"
                       :disabled="this.userInfo.pkSettleCountry == 1"
                       :placeholder="$t('w_0064')">
              <el-option v-for="item in transList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 收货人信息 -->
      <div v-show="isDeliveryWay">
        <div class="xian1">
          <div class="title">{{ $t('w_0065') }}</div>
          <div class="lan"
               @click="openAddress">{{ $t('w_0066') }}</div>
        </div>
        <el-row :gutter="40"
                class="padRow">
                <el-col :span="6">
            <el-form-item :label="$t('MY_ORD_10')" prop="recName" :required="isDeliveryWay">
              <el-input
                v-model="ruleForm.recName"
               :maxlength="40"
                :placeholder="$t('w_0067')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-if="pkCountry == 1">
            <el-form-item :label="$t('w_0052')" prop="recPhone" :required="isDeliveryWay">
              <el-input
                v-model="ruleForm.recPhone"
                :maxlength="11"
                oninput="value=value.replace(/[^\d]/g,'')"
                :placeholder="$t('w_0053')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-else>
            <el-form-item :label="$t('w_0052')" prop="recPhone" :required="isDeliveryWay">
              <el-input
                v-model="ruleForm.recPhone"
                :maxlength="30"
                :placeholder="$t('w_0053')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('w_0068')"
                          class="dizhi"
                          :required="isDeliveryWay"
                          prop="dizhi">
              <el-cascader v-model="ruleForm.dizhi"
                           @change="setDizhi"
                           :options="areaData"
                           :props="regionParams"
                           :placeholder="$t('w_0069')"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('S_C_27')"
                          :required="isDeliveryWay"
                          prop="recAddress">
              <el-input v-model="ruleForm.recAddress"
                        maxlength="200"
                        :placeholder="$t('w_0070')"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40"
                class="padRow">
          <el-col :span="6">
            <el-form-item :label="$t('w_0071')">
              <el-input v-model="ruleForm.postage"
                        disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('MY_WAL_22')">
              <el-input v-model="ruleForm.remark"
                        :placeholder="$t('w_0072')"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <!-- 历史地址 -->
    <div v-show="isDeliveryWay">
      <div class="nox">
        <div class="title">{{ $t('w_0073') }}</div>
      </div>
      <div class="local">
        <div :class="['local_i', item.pkId == moren ? 'local_a' : '']"
             v-for="item in addressList"
             :key="item.pkId">
          <el-radio  v-model="moren"
                    @input="checkRadio"
                    :label="item.pkId"><span></span></el-radio>
          <div class="local1">
            {{ item.recArea }}{{ item.recAddress }}({{ item.recName }} 收)
          </div>
          <div class="local2"
               v-show="item.isDefault == 1">{{ $t('w_0074') }}</div>
          <div class="local2 local3"
               v-show="item.isDefault == 0"
               @click="setDefaultAds(item.pkId)">
            {{ $t('w_0075') }}
          </div>
          <div class="local2"
               @click="changeAds(item.pkId)">{{ $t('w_0076') }}</div>
          <div class="local3"
               @click="delAds(item.pkId)">{{ $t('ENU_CONTROL_TYPE_3') }}</div>
        </div>
      </div>
    </div>
    <!-- 商品信息 -->
    <div class="nox">
      <div class="title">{{ $t('w_0077') }}</div>
    </div>
    <el-table :data="allData"
              :row-style="{ height: '60px', fontSize: '15px', color: '#333333' }"
              :cell-style="{ padding: '0' }"
              :fit="true"
              :header-cell-style="{
                        background: '#C8161D',
                        color: '#fff',
                        fontWeight: 'bold',
                        fontSize: '15px',
                      }"
              :row-class-name="tableRowClassName">
      <el-table-column align="center"
                       prop="waresName"
                       width="400"
                       :label="$t('w_0078')">
        <template slot-scope="scope">
          <!-- <span>{{ scope.row.goodsPrice | numberToCurrency}}</span> -->
          <div class="goods">
            <div class="goods_t">
              <img :src="scope.row.cover1"
                   alt="" />
              <div>{{ scope.row.waresName }}</div>
            </div>
            <div class="goods_tit"
                 v-for="item in scope.row.waresItemsParamList"
                 :key="item.pkWaresSpecsSku">
              <div class="goods_tit_l">
                <img :src="item.specCover"
                     alt="" />
                <div class="goods_tit_lr">
                  <div>
                    {{ item.productName }}
                  </div>
                  <div>x {{ item.quantity }}</div>
                </div>
              </div>
              <div class="goods_tit_r">
                {{ item.skuInfo }}
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center"
                       prop="goodsPrice"
                       :label="$t('w_0079')">
        <template slot-scope="scope">
          <span>{{ scope.row.price | numberToCurrency }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center"
                       prop="goodsAchive"
                       :label="$t('w_0080')+'(PV)'">
        <template slot-scope="scope">
          <span>{{ scope.row.achieve | numberToCurrency }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center"
                       prop="quantity"
                       :label="$t('w_0081')">
      </el-table-column>
      <el-table-column align="center"
                       prop="spyjxj"
                       :label="$t('w_0082')+'PV'">
<template slot-scope="scope">
  <span>{{ scope.row.achieveAmount | numberToCurrency }}</span>
</template>
      </el-table-column>
      <el-table-column align="center"
                       prop="pvxj"
                       :label="$t('w_0093')">
<template slot-scope="scope">
  <span>{{ scope.row.priceAmount | numberToCurrency }}</span>
</template>
      </el-table-column>
    </el-table>
    <div class="tab_b">
      <div>{{ $t('w_0083') }}:{{ userInfo.currencyIcon }}{{ allGoodsData.priceAmount | numberToCurrency }}</div>
      <div>{{ $t('w_0071') }}:{{ userInfo.currencyIcon }}{{ ruleForm.postage | numberToCurrency }}</div>
    </div>
    <AddAddress ref="addAddress"
                @getAdList="getAddressList"></AddAddress>
    <div class="zhan"></div>
    <!-- 购买 -->
    <div class="tfooter">
      <div class="footer_t">
        <div class="footer_tt">
          <div class="dis_flex">
            <div class="f1">{{ $t('w_0325') }}:</div>
            <div class="f2">{{ userInfo.currencyIcon }}{{ totalAmont | numberToCurrency }}</div>
          </div>
          <div class="dis_flex">
            <div class="f1">{{ $t('ENU_TOTAL_V_1') }}:</div>
            <div class="f3">
              {{ allGoodsData.achieveAmount | numberToCurrency }}
            </div>
          </div>
        </div>
        <div class="footer_tt"
             v-show="
            ruleForm.recAddress &&
            ruleForm.recName &&
            ruleForm.recPhone &&
            ruleForm.nameList
          ">
          <div class="f2 f4">
            {{ $t('w_0084') }}：{{ ruleForm.nameList }}{{ ruleForm.recAddress }}
          </div>
          <div class="f4">
            {{ $t('MY_ORD_10') }}：{{ ruleForm.recName }} {{ ruleForm.recPhone }}
          </div>
        </div>
      </div>
      <div class="footer_b">
        <!-- 协议 -->
        <agreement :isShow="agreementShow"
                   ref="agree"
                   @agree="agree"></agreement>
        <div class="btn"
             @click="submitRegister('ruleForm')">{{ $t('w_0085') }}</div>
      </div>
    </div>
    <!-- 强制阅读协议 -->
    <mustAgreeMent :waresCodeList='waresCodeList'></mustAgreeMent>
    <Pay :isPay="isPay"
         @closePay="closePay"
         :paramsPost="paramsPost"></Pay>
  </div>
</template>

<script>
import personInfo from '@/components/personInfo.vue'
import AddAddress from '@/components/AddAddress.vue'
import agreement from '@/components/agreement.vue'
import { mapGetters } from 'vuex'
import * as api from '@/api/register.js'
import { setTokenMi } from '@/util/auth'
import Pay from '@/components/Pay.vue'
import mustAgreeMent from '@/components/mustAgreeMent.vue'

export default {
  components: {
    personInfo,
    AddAddress,
    agreement,
    Pay,
    mustAgreeMent,
  },
  data() {
    // 验证活动名称的函数
    let validateRecName = (rule, value, callback) => {
      if (this.ruleForm.recName === '' && this.isDeliveryWay) {
        callback(new Error(this.$t('w_0087')))
      } else {
        callback()
      }
    }
    let validateRecPhone = (rule, value, callback) => {
      if (this.ruleForm.recPhone === '' && this.isDeliveryWay) {
        callback(new Error(this.$t('w_0053')))
      } else {
        callback()
      }
    }
    let validateRecAddress = (rule, value, callback) => {
      if (this.ruleForm.recAddress === '' && this.isDeliveryWay) {
        callback(new Error(this.$t('w_0070')))
      } else {
        callback()
      }
    }
    let validateRecDizhi = (rule, value, callback) => {
      if (this.ruleForm.dizhi.length == 0 && this.isDeliveryWay) {
        callback(new Error(this.$t('w_0069')))
      } else {
        callback()
      }
    }
    let validateRecTransType = (rule, value, callback) => {
      if (this.ruleForm.transType == '' && this.isDeliveryWay) {
        callback(new Error(this.$t('w_0064')))
      } else {
        callback()
      }
    }
    return {
      waresCodeList: [],
      isPay: false,
      paramsPost: {},
      isClick: 0,
      isDeliveryWay: true,
      ruleForm: {
        fwzx: '',
        placeDept: '',
        pkGradeVal: '',
        dizhi: [],
        dzName: ['', '', ''],
        postage: '',
        pkCountry: '',
        pkSettleCountry: '',
        parent: '',
        parentName: '',
        placeParent: '',
        placeParentName: '',
        recName: '',
        recPhone: '',
        recAddress: '',
        nameList: '',
        memberName: '',
        phone: '',
        idCard: '',
        pkBank: '',
        cardNumber: '',
        subBankName: '',
        email: '',
        deliveryWay: '',
        transType: '',
      },
      rules: {
        fwzx: [
          { required: true, message: '请输入活动名称', trigger: 'change' },
        ],
        pkGradeVal: [
          { required: true, message: this.$t('w_0086'), trigger: 'change' },
        ],
        memberName: [
          { required: true, message: this.$t('w_0274'), trigger: 'blur' },
        ],
        // recName: [{ message: '请输入收件人', trigger: 'blur' }],
        recName: [{ validator: validateRecName }],
        // recPhone: [{ message: this.$t('w_0053'), trigger: 'blur' }],
        recPhone: [{ validator: validateRecPhone }],
        // recAddress: [{ message: this.$t('w_0070'), trigger: 'blur' }],
        recAddress: [{ validator: validateRecAddress }],
        dizhi: [{ validator: validateRecDizhi }],

        // dizhi: [{ message: this.$t('w_0069'), trigger: 'change' }],
        parent: [
          { required: true, validator: this.parentPass, trigger: 'blur' },
        ],
        parentName: [
          { required: true, message: this.$t('w_0265'), trigger: 'change' },
        ],
        placeParent: [
          { required: true, validator: this.placeParentPass, trigger: 'blur' },
        ],
        placeParentName: [
          { required: true, message: this.$t('w_0267'), trigger: 'change' },
        ],
        phone: [{ required: true, validator: this.phonePass, trigger: 'blur' }],
        pkCountry: [
          {
            required: true,
            message: this.$t('w_0275'),
            trigger: 'change',
          },
        ],
        pkSettleCountry: [
          {
            required: true,
            message: this.$t('w_0276'),
            trigger: 'change',
          },
        ],
        deliveryWay: [
          {
            required: true,
            message: this.$t('w_0062'),
            trigger: 'change',
          },
        ],
        placeDept: [
          {
            required: true,
            validator: this.placeDeptPass,
            trigger: 'change',
          },
        ],
        // transType: [
        //   {
        //     required: true,
        //     message: '请选择运输方式',
        //     trigger: 'change',
        //   },
        // ],
        transType: [{ validator: validateRecTransType }],
        email: [
          { required: false, trigger: 'blur' },
          {
            pattern:
              /^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/g,
            message: this.$t('w_0277'),
            trigger: 'blur',
          },
        ],
      },
      addressList: [],
      moren: '',
      deptOption: [
        {
          value: 1,
          name: this.$t('ENU_PLACE_D_1'),
        },
        {
          value: 2,
          name: this.$t('ENU_PLACE_D_2'),
        },
      ],
      allGoodsData: {},
      allData: [],
      agreementShow: false,
      countryList: [],
      transList: [
        // {
        //   value: 1,
        //   label: '陆运',
        // },
        // {
        //   value: 2,
        //   label: '空运',
        // },
        // {
        //   value: 3,
        //   label: '海运',
        // },
      ],
      deliList: [],
      bankLists: [],
      areaData: [],
      regionParams: {
        label: 'name',
        value: 'id',
        children: 'children',
        expandTrigger: 'hover',
      },
      totalPrice: 0,
      totalAchive: 0,
      isLoading: '',
      shoppingArr: [],
      totalAmont: 0,
      specialArea: '',
      pt: false,
      pkCountry:''
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {
    this.pkCountry = parseInt(localStorage.getItem("pkCountry"));
    this.specialArea = this.$route.query.specialArea
    // 数据源
    this.shoppingArr = JSON.parse(localStorage.getItem('allGoods'))
    // 获取购物车详情
    this.getCatDetail()
    // 幂等性
    api.generate().then((res) => {
      setTokenMi(res.msg)
    })
  },
  methods: {
    getCatDetail() {
      api
        .shoppingWaresDetail({
          shoppingIds: this.shoppingArr,
          specialArea: this.specialArea,
        })
        .then((res) => {
          this.allGoodsData = res.data
          this.waresCodeList = res.data.orderItemsParams.map((item) => {
            return item.waresCode
          })
          this.allGoodsData.specialArea = this.specialArea
          this.allData = this.allGoodsData.orderItemsParams
          this.totalAmont = this.allGoodsData.priceAmount
          // 服务中心赋值
          this.ruleForm.fwzx = this.userInfo.memberCode
          // 快速注册/普通注册
          this.toRegiest(this.isClick)

          // 获取地址
          this.getAddressList()
          // 获取国家
          this.getCountry()
          // 获取运输方式
          this.getTransList()
          // 获取发货方式
          this.getDeliveryList()
          // 获取银行
          this.getBank()
          // 获取省市县
          this.getAllArea()
          // 获取会员等级
          this.getMember()
        })
    },
    selDelivery(e) {
      if (e == 2 || e == 3) {
        this.isDeliveryWay = false
      } else {
        this.isDeliveryWay = true
      }
    },
    openLoading() {
      this.isLoading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
    },
    toRegiest(val) {
      this.isClick = val
      this.resetAll()
      if (val == 0) {
        // 推荐编号赋值
        this.ruleForm.parent = this.userInfo.memberCode
        // 推荐姓名赋值
        this.ruleForm.parentName = this.userInfo.memberName
        // 从推荐编号获取安置信息
        // this.getQuickReg()
        this.getValidPcode()
      } else {
      }
      if (this.userInfo.pkSettleCountry == 1) {
        this.ruleForm.transType = 1
        this.ruleForm.deliveryWay = 1
      }
    },
    resetAll() {
      this.ruleForm.parent = ''
      this.ruleForm.parentName = ''
      this.ruleForm.placeParent = ''
      this.ruleForm.placeParentName = ''
      this.ruleForm.placeDept = ''
      this.ruleForm.memberName = ''
      this.ruleForm.phone = ''
      this.ruleForm.idCard = ''
      this.ruleForm.pkBank = ''
      this.ruleForm.cardNumber = ''
      this.ruleForm.subBankName = ''
      this.ruleForm.email = ''
      this.ruleForm.deliveryWay = ''
      this.ruleForm.transType = ''
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
      })
    },
    closePay(val) {
      if (val) {
        this.isPay = false
        this.$router.push({
          path: '/vipCenter',
          query: { id: 1 },
        })
      } else {
        this.$confirm(this.$t('w_0090') + '?')
          .then((_) => {
            this.isPay = false
            this.$router.push({
              path: '/vipCenter',
              query: { id: 1 },
            })
          })
          .catch((_) => {})
      }
    },
    // 注册
    submitRegister(formName) {
      let deleteList = []
      this.shoppingArr.forEach((item) => {
        deleteList.push({
          shoppingId: item,
        })
      })
      this.$refs[formName].validate((valid) => {
        if (!this.agreementShow) {
          this.$message({
            message: this.$t('w_0091'),
            type: 'warning',
          })
          return
        }
        if (valid) {
          this.openLoading()
          let obj = Object.assign({}, this.allGoodsData, this.ruleForm, {
            deleteList: deleteList,
          })
          api
            .confirmFunOrder(obj)
            .then((res) => {
              this.isLoading.close()
              if (res.code == 200) {
                this.paramsPost = res.data
                this.isPay = true
              }
            })
            .catch((err) => {
              this.isLoading.close()
            })
        } else {
          return false
        }
      })
      // let obj = Object.assign({}, this.allGoodsData[0], this.ruleForm)
      // console.log(
      //   '%c [ obj ]-498',
      //   'font-size:13px; background:#88187e; color:#cc5cc2;',
      //   obj
      // )
    },
    flatten(arr) {
      return arr.reduce((result, item) => {
        return result.concat(
          item,
          Array.isArray(item.children) ? this.flatten(item.children) : []
        )
      }, [])
    },
    setDizhi(e, val) {
      let arr = this.flatten(this.areaData)
      let nameList = ''
      arr.forEach((item) => {
        this.ruleForm.dizhi.forEach((ctem) => {
          if (ctem == item.id) {
            nameList += item.name
          }
        })
      })
      this.ruleForm.nameList = nameList
      this.allGoodsData.recProvince = this.ruleForm.dizhi[0]
      this.allGoodsData.recCity = this.ruleForm.dizhi[1]
      this.allGoodsData.recCounty = this.ruleForm.dizhi[2] || ''
      this.allGoodsData.recName = this.ruleForm.recName
      this.allGoodsData.recPhone = this.ruleForm.recPhone
      this.allGoodsData.recAddress = this.ruleForm.recAddress
      this.allGoodsData.deliveryWay = this.ruleForm.deliveryWay
      this.allGoodsData.transType = this.ruleForm.transType
      this.getPostAge()
    },
    checkRadio() {
      this.$set(this.ruleForm, 'dizhi', [])
      let arr = []
      this.addressList.forEach((item) => {
        if (item.pkId == this.moren) {
          this.ruleForm.recName = item.recName
          this.ruleForm.nameList = item.recArea
          this.ruleForm.recPhone = item.recPhone
          this.ruleForm.recAddress = item.recAddress
          arr = [
            item.recProvince + '',
            item.recCity + '',
            (item.recCounty || '') + '',
          ]
          // this.$set(this.ruleForm, 'dizhi', [
          //   item.recProvince + '',
          //   item.recCity + '',
          //   item.recCounty + '',
          // ])
        }
      })
      arr = arr.filter((s) => {
        return s && s.trim()
      })
      this.$set(this.ruleForm, 'dizhi', arr)
      this.allGoodsData.deliveryWay = this.ruleForm.deliveryWay
      this.allGoodsData.transType = this.ruleForm.transType
      this.allGoodsData.recProvince = this.ruleForm.dizhi[0]
      this.allGoodsData.recCity = this.ruleForm.dizhi[1]
      this.allGoodsData.recCounty = this.ruleForm.dizhi[2]
      this.allGoodsData.recName = this.ruleForm.recName
      this.allGoodsData.recPhone = this.ruleForm.recPhone
      this.allGoodsData.recAddress = this.ruleForm.recAddress
      this.getPostAge()
      this.$forceUpdate()
      console.log(
        '%c [ this.ruleForm ]-813',
        'font-size:13px; background:#982b93; color:#dc6fd7;',
        this.ruleForm
      )
    },
    getPostAge() {
      let deleteList = []
      this.shoppingArr.forEach((item) => {
        deleteList.push({
          shoppingId: item,
        })
      })
      this.allGoodsData.deleteList = deleteList
      api.queryPostage(this.allGoodsData).then((res) => {
        this.ruleForm.postage = Number(res.data.postage).toFixed(2)
        this.totalAmont =
          parseFloat(this.allGoodsData.priceAmount) +
          parseFloat(res.data.postage)
      })
    },
    getMember() {
      let deleteList = []
      this.shoppingArr.forEach((item) => {
        deleteList.push({
          shoppingId: item,
        })
      })
      this.allGoodsData.deleteList = deleteList
      api.memLevel(this.allGoodsData).then((res) => {
        this.ruleForm.pkGradeVal = res.data.pkGradeVal
      })
    },
    phonePass(rule, value, callback) {
      if (!value) {
        callback(new Error(this.$t('w_0278')))
      } else {
        callback()
        // api
        //   .validPhone({
        //     memberName: this.ruleForm.memberName,
        //     phone: this.ruleForm.phone,
        //     placeParent: this.ruleForm.placeParent,
        //   })
        //   .then((res) => {
        //     if (res.code == 200) {
        //       callback()
        //     } else {
        //       callback(new Error(res.msg))
        //     }
        //   })
      }
    },
    parentPass(rule, value, callback) {
      if (!value) {
        callback(new Error(this.$t("w_0264")));
      } else {
        // if (this.isClick == 0) {
        //   this.getQuickReg()
        // } else {

        // }
        // this.getValidPcode()
        callback();
      }
    },
    // 安置编号校验
    placeParentPass(rule, value, callback) {
      if (!value) {
        callback(new Error(this.$t('w_0266')))
      } else {
        api
          .validPPcode({
            parent: this.ruleForm.parent,
            placeParent: value,
            placeDept: this.ruleForm.placeDept,
          })
          .then((res) => {
            if (res.code == 200) {
              this.ruleForm.placeParentName = res.data.placeParentName
              this.$forceUpdate()
              callback()
            } else {
              callback(new Error(res.msg))
            }
          })
      }
    },
    placeDeptPass(rule, value, callback) {
      if (!value) {
        callback(new Error(this.$t('w_0268')))
      } else {
        api
          .validPPcode({
            parent: this.ruleForm.parent,
            placeParent: this.ruleForm.placeParent,
            placeDept: value,
          })
          .then((res) => {
            if (res.code == 200) {
              this.ruleForm.placeParentName = res.data.placeParentName
              this.$forceUpdate()
              callback()
            } else {
              callback(new Error(res.msg))
            }
          })
      }
    },
    getAllArea() {
      api.allArea().then((res) => {
        this.areaData = res.data
      })
    },
    getBank() {
      api.bankList().then((res) => {
        this.bankLists = res.data
      })
    },
    getTransList() {
      api.transportList().then((res) => {
        this.transList = res.data
      })
    },
    getDeliveryList() {
      api.deliveryList().then((res) => {
        this.deliList = res.data
      })
    },
    getQuickReg() {
      api
        .quickReg({
          parent: this.ruleForm.parent,
          specialArea: 1,
        })
        .then((res) => {
          if (res.code == 200) {
            this.ruleForm.placeDept = res.data.placeDept
            this.ruleForm.placeParent = res.data.placeParent
            this.ruleForm.placeParentName = res.data.placeParentName
            this.ruleForm.parentName = res.data.parentName;
            if (res.data.isFirst) {
              // 普通注册首单
              this.pt = true
            } else {
              // 普通注册首单
              this.pt = false
            }
            // 根据推荐人编号获取安置信息覆盖快速注册信息
            // this.getValidPcode()
            this.$forceUpdate()
          }
        })
    },
    getValidPcode() {
      api
        .validPcode({
          parent: this.ruleForm.parent,
        })
        .then((res) => {
          if (res.code == 200) {
            // this.ruleForm.parentName = res.data.parentName
            this.getQuickReg()
            this.$forceUpdate()
          }
        })
    },
    // 自然国选择国旗、
    changeSelection(e) {
      let optionsImg = this.countryList,
        i = optionsImg.findIndex((item) => item.pkId == e)
      this.$refs['select'].$el.children[0].children[0].setAttribute(
        'style',
        `
				background: url(${optionsImg[i].nationalFlag2}) no-repeat;
				background-position: 10px center;
				background-size: 28px 19px;
				text-indent: 30px;
				`
      )
    },
    // 结算国选择国旗
    changeSelection1(e) {
      let optionsImg = this.countryList,
        i = optionsImg.findIndex((item) => item.pkId == e)
      this.$refs['select'].$el.children[0].children[0].setAttribute(
        'style',
        `
				background: url(${optionsImg[i].nationalFlag2}) no-repeat;
				background-position: 10px center;
				background-size: 28px 19px;
				text-indent: 30px;
				`
      )
    },
    getCountry() {
      api.currencyList().then((res) => {
        this.countryList = res.data
        this.countryList.forEach((item) => {
          if (item.pkId == this.userInfo.pkSettleCountry) {
            this.ruleForm.pkCountry = item.pkId
            this.ruleForm.pkSettleCountry = item.pkId
            // 自然国图片赋值
            this.$refs['select'].$el.children[0].children[0].setAttribute(
              'style',
              `
				background: url(${item.nationalFlag2}) no-repeat;
				background-position: 10px center;
				background-size: 28px 19px;
				text-indent: 30px;
				`
            )
            // 结算国图片赋值
            this.$refs['select1'].$el.children[0].children[0].setAttribute(
              'style',
              `
				background: url(${item.nationalFlag2}) no-repeat;
				background-position: 10px center;
				background-size: 28px 19px;
				text-indent: 30px;
				`
            )
          }
        })
      })
    },
    delAds(id) {
      this.$confirm(this.$t('w_0088') + '?', '', {
        confirmButtonText: this.$t('w_0035'),
        cancelButtonText: this.$t('ENU_P_TYPE0'),
        type: 'warning',
      }).then(() => {
        api.delAddress(id).then((res) => {
          this.$message({
            type: 'success',
            message: this.$t('w_0089') + '!',
          })
          this.getAddressList()
        })
      })
    },
    changeAds(id) {
      this.$refs.addAddress.isShowAddress(true, this.areaData, id)
    },
    setDefaultAds(id) {
      api.addressDefault(id).then((res) => {
        this.getAddressList()
      })
    },
    // 同意协议
    agree(val) {
      this.agreementShow = val
    },
    openAddress() {
      this.$refs.addAddress.isShowAddress(true, this.areaData)
    },
    getAddressList() {
      api.addressList({}).then((res) => {
        this.addressList = res.data
        this.addressList.forEach((item) => {
          if (item.isDefault == 1) {
            this.moren = item.pkId
            this.checkRadio()
          }
        })
        // this.moren = this.addressList[0].pkId
      })
    },
    // 斑马线
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return 'warning-row'
      } else if (rowIndex % 2 == 1) {
        return 'success-row'
      }
      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
.dis_flex {
  display: flex;
  align-items: center;
}

.rt {
  border-bottom: 5px solid rgba(7, 7, 7, 0.05);
  padding: 45px 60px 15px 60px;
  .rti {
    margin-right: 80px;
  }
  .zc {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-right: 20px;
  }
  .zca {
    font-size: 18px;
    font-weight: bold;
    color: #b42b2a;
  }
}

.demo-ruleForm {
  margin-top: 20px;
  overflow: hidden;
}

.padRow {
  padding: 0 60px;
}

::v-deep .el-input__suffix {
  right: auto;
  .el-input__validateIcon {
    position: absolute;
  }
}

::v-deep .el-select {
  width: 100%;
}

::v-deep .el-select .el-input__suffix {
  right: 0;
}

::v-deep .el-icon-circle-check {
  color: #009900;
}

::v-deep .el-form-item__label {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 28px;
}

.xian1 {
  padding: 10px 60px;
  border-bottom: 5px solid rgba(7, 7, 7, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.nox {
  padding: 10px 60px;
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
}

.lan {
  font-size: 18px;
  font-weight: 400;
  color: #3499ff;
  cursor: pointer;
}

.local {
  padding: 0px 60px;
  .local_i {
    display: flex;
    align-items: center;
    padding: 30px 90px;
  }
  .local_a {
    background: #eeeeee;
    border-radius: 8px 8px 8px 8px;
  }
  .local1 {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    margin-right: 50px;
  }
  .local2 {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    margin-right: 70px;
    cursor: pointer;
  }
  .local3 {
    font-size: 14px;
    font-weight: 400;
    color: #f92a28;
    cursor: pointer;
  }
}

::v-deep .el-radio {
  margin-right: 0;
}

.guoqi {
  .el-select-dropdown__item {
    height: 26px; // text-align: center;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  .shortName {
    margin-left: 10px;
  }
}

.guoqikang {
  ::v-deep input {
    // height: 100px;
  }
}

.imgSize {
  height: 20px;
}

.dizhi {
  ::v-deep .el-cascader {
    width: 100%;
  }
}

::v-deep .el-cascader .el-input__suffix {
  right: 0;
}

.zhan {
  height: 250px;
}

.tfooter {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 10;
  .footer_t {
    padding: 5px 60px 15px 60px;
    background: #ededed;
    .footer_tt {
      justify-content: right;
      display: flex;
      margin-top: 10px;
    }
  }
  .footer_b {
    text-align: right;
    padding: 10px 60px 10px 60px;
    background: #fff;
  }
}

.f1 {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.f2 {
  font-size: 18px;
  font-weight: 500;
  color: #f43c3a;
  margin-right: 40px;
}

.f3 {
  font-size: 14px;
  font-weight: 400;
  color: #384a8a;
}

.f4 {
  font-size: 14px;
  font-weight: 400;
  color: #666;
}

.btn {
  background: #d61820;
  border-radius: 8px 8px 8px 8px;
  padding: 12px 40px;
  display: inline-block;
  color: #fff;
  font-size: 18px;
  margin-top: 10px;
  cursor: pointer;
}

.goods {
  text-align: left;
  padding-top: 20px;
  padding-left: 50px;
}

.goods_t {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #333333; // justify-content: center;
  img {
    width: 88px;
    height: 88px;
    margin-right: 10px;
  }
}

.goods_tit {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  margin-top: 10px;
  display: flex;
  img {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }
  .goods_tit_l {
    flex: 1;
    display: flex;
    align-items: center;
  }
  .goods_tit_r {
    width: 200px;
  }
}

.tab_b {
  text-align: right;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  padding: 20px 60px 0 0;
}

.option_box {
  display: flex;
  align-items: center;
}

.option_img {
  width: 35px;
  height: 22px;
  margin-right: 7px;
}

::v-deep .el-cascader .el-input__suffix {
  right: 0;
}
</style>
